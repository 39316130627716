<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter header-filter"
              parallax-active="true"
              :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              <img :src="image_head_title"/>
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div class="ac-nft-filter-wrap md-layout-item md-size-25 md-small-size-100">
              <md-card class="md-card md-card-refine md-card-plain">
                <md-card-content>
                  <h4 class="card-title">
                    State Filter
                  </h4>

                  <el-collapse v-model="activeNames">
                    <el-collapse-item v-for="(category, key) in filters.state"
                                      :title="key"
                                      :name="key"
                                      :key="key">
                      <md-checkbox v-for="(item, itemKey) in category"
                                   v-model="item.isApply"
                                   :key="itemKey"
                                   @change="onFilter('state')"
                                   class="mb-0">
                        {{item.name}}
                        {{item.isApply}}
                      </md-checkbox>
                    </el-collapse-item>
                  </el-collapse>
                </md-card-content>
                <md-card-content>
                  <h4 class="card-title">
                    Attr Filter
                  </h4>
                  <el-collapse v-model="activeNames">
                    <el-collapse-item v-for="(category, key) in filters.attribute"
                                      :title="key"
                                      :name="key"
                                      :key="key">
                      <md-checkbox v-for="(item, itemKey) in category"
                                   v-model="item.isApply"
                                   :key="itemKey"
                                   @change="onFilter('attribute')"
                                   class="mb-0">
                        {{item.name}}
                      </md-checkbox>
                    </el-collapse-item>
                  </el-collapse>
                </md-card-content>
              </md-card>
              <md-button class="ac-nft-filter-reset"
                         @click="onResetFilter">
                reset
              </md-button>
            </div>
            <div class="md-layout-item md-size-75 md-small-size-100">
              <div v-if="isLoading">
                <div id="loading">
                  <div class="spinner"></div>
                </div>
              </div>
              <div v-else>
                <div class="ac-nft-list-header">

                  <div class="md-layout">
                    <strong class="md-layout-item md-size-50 md-small-size-100">
                      Item : {{nft_filtered_list.length}}
                    </strong>
                    <div class="md-layout-item md-size-50 md-small-size-100 text-right">
                      <el-input placeholder="input token ID"
                                v-model="searchKeyword"
                                @input="onSearch">
                        <template slot="prepend">ANIPANG #</template>
                      </el-input>
                    </div>
                  </div>
                </div>
                <br>
                <div ref="ac-nft-list-wrap"
                     class="ac-nft-list-wrap">
                  <div class="md-layout">
                    <div v-for="(nft) in nft_view_list"
                         :key="nft.token_id"
                         @click="showDetailNFTModal(nft)"
                         class="md-layout-item md-size-33 md-medium-size-50">
                      <ACProductCard text-center
                                    class="mt-3"
                                    card-plain
                                    :card-image="nft.image"
                                    :shadow-off="true">
                        <template slot="cardContent">
                          <p class="card-title">
                            {{ nft.name }}<br>
                          </p>
                        </template>
                      </ACProductCard>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import {
    ACProductCard,
    // Modal,
  } from "@/components";
  // import { LoginCard, PricingCard, Modal } from "@/components";
  import Mixins from "@/plugins/basicMixins";
  import qs from 'qs';

  export default {
    bodyClass: "ac-nft-gallery",
    components: {
      ACProductCard,
      // Modal
    },
    mixins: [Mixins.HeaderImage, Mixins.ACGalleryScroll],
    computed: {
      ...mapGetters([
        "wemix",
        "publicNFT",
        "isLoading",
      ]),
    },
    watch: {
      $route() {
        this.GET_PUBLIC_NFT_LIST_DATA();
      },
      publicNFT(value) {
        this.filters = value.filters;
        this.nft_filtered_list = value.list;
        this.makeViewList('reset');
      }
    },
    mounted() {
      this.GET_PUBLIC_NFT_LIST_DATA();
    },
    methods: {
      ...mapActions([
        'GET_PUBLIC_NFT_LIST_DATA',
      ]),

      onSearch(keyword) {
        const maxValueLength = 4;
        let value = '';

        this.onResetFilter();
        if (keyword.length > maxValueLength) {

        } else if (!isNaN(Number(keyword))) {
          value = keyword;
        }
        this.searchKeyword = value;
        this.makeSearchList();
        return value.toString();
      },
      
      makeSearchList() {
        let nftSearchList = [];

        if (this.searchKeyword.length > 0) {
          this.publicNFT.list.forEach((nft) => {
            if (nft.name.includes(this.searchKeyword)) {
              nftSearchList.push(nft);
            }
          });
        } else {
          nftSearchList = this.publicNFT.list
        }
        this.nft_filtered_list = nftSearchList;
        this.makeViewList('reset')
      },

      onFilter(type) {
        this.searchKeyword = '';
        this.selectedFilters[type] = {};

        Object.keys(this.filters[type]).forEach((category) => {
          Object.keys(this.filters[type][category]).forEach((filter) => {
            if (this.filters[type][category][filter].isApply) {
              if (!Array.isArray(this.selectedFilters[type][category]) ) {
                this.selectedFilters[type][category] = [];
              }
              this.selectedFilters[type][category].push(this.filters[type][category][filter].name);
            }
          });
        });

        this.makeFilteredList();
      },

      makeFilteredList() {
        let nftFilteredList = [];

        if (Object.keys(this.selectedFilters['attribute']).length === 0 && Object.keys(this.selectedFilters['state']).length === 0) {
          nftFilteredList = this.publicNFT.list
        } else {
          this.publicNFT.list.forEach((nft) => {
            let isShow = true;

            Object.keys(this.selectedFilters['attribute']).forEach((filter) => {
              if (!this.selectedFilters['attribute'][filter].includes(nft.attribute[filter])) {
                isShow = false;
              }
            });

            Object.keys(this.selectedFilters['state']).forEach((filter) => {
              if (!this.selectedFilters['state'][filter].includes(nft[filter.toLowerCase()])) {
                isShow = false;
              }
            });
            if (isShow) nftFilteredList.push(nft);
          });
        }

        this.nft_filtered_list = nftFilteredList;
        this.makeViewList('reset')
      },

      onResetFilter() {
        Object.keys(this.filters).forEach((type) => {
          Object.keys(this.filters[type]).forEach((category) => {
            Object.keys(this.filters[type][category]).forEach((filter) => {
              this.filters[type][category][filter].isApply = false;
            });
          });
        });

        this.selectedFilters = {
          state: {},
          attribute: {},
        };
        this.searchKeyword = '';
        this.activeNames = ['Availability'];
        this.makeFilteredList();
      },

      showDetailNFTModal(selectNFT) {
        this.$router.push(`/NFT/detail/${selectNFT.tokenId}`);
        this.selectNFT = selectNFT;
      },

      // 무한 스크롤 정의
      handleNotificationListScroll(e) {
        const { scrollHeight, scrollTop, clientHeight } = e.target;
        const isAtTheBottom = scrollHeight <= scrollTop + clientHeight + 100 ;

        if (isAtTheBottom) this.makeViewList('add');
      },

      makeViewList(state) {
        if (state === 'reset') {
          this.page_index = 1;
        } else {
          this.page_index += 1;
        }

        this.nft_view_list = this.nft_filtered_list.slice(0, this.page_index * this.page_amount);
      },

      // 스크롤을 맨위로 올리고 싶을 때
      handleClickTitle() {
        this.$refs["ac-nft-list-wrap"].scroll({ top: 0, behavior: "smooth" });
      },

      // 새로고침
      handleClickRefresh() {
        this.$refs["ac-nft-list-wrap"].scroll({ top: 0 });
        this.dispatchGetNotifications(true);
      },

      // 처음 렌더링시 이전 알림 불러오기 or reset=true시 새로고침, false시 이전 목록에 추가
      dispatchGetNotifications(reset) {
        this.$store.dispatch("notification/getNotifications", reset);
      }
    },
    data() {
      return {
        // isShowDetailNFTModal: false,
        selectNFT: null,

        activeNames: ['Availability'],

        subscribe: null,


        image_head_bg: require("@//assets/img/pages/nft/04_news_bg2-2.png"),
        image_head_title: require("@//assets/img/pages/nft/gallery.png"),


        // attr_filter: {},
        // state_filter: {},
        selectedFilters: {
          state: {},
          attribute: {},
        },
        filters: {},
        nft_filtered_list: [],
        nft_view_list: [],
        page_index: 1,
        page_amount: 20,
        searchKeyword: '',

      };
    },
  }
</script>

<style lang="scss">
  /*@font-face {*/
    /*font-family: 'yg-jalnan';*/
    /*src: url('../../assets/fonts/Jalnan.ttf') format('truetype'); !* chrome, firefox, opera, Safari, Android, iOS 4.2+*!*/
    /*font-weight: normal;*/
    /*font-display: 'auto';*/
    /*font-style: normal;*/
  /*}*/

  .ac-nft-gallery {
    .wrapper {
      .container {

        .ac-nft-list-header {

        }
        .ac-nft-list-wrap {
          /*height: calc(100vh - 100px);*/
          overflow-x: hidden;
        }
        .ac-nft-filter-wrap {
          /*height: calc(100vh);*/
          overflow: auto;
          margin: 0;

          .md-card {
            margin: 0;
          }

          .ac-nft-filter-reset {
            background-color: #6825cc !important;
            border-radius: 30px;
            margin: 0 auto;
            display: block;
          }

          .el-collapse-item__content {
            padding-left: 15px;
            padding-top: 15px;

            .md-checkbox {
              display: flex;
              justify-content: left;
              margin: 0 0 10px 0;
            }
          }
        }
      }
    }



    .page-header{
      height: 420px;
      background-position: center center;

      img {
        /*width: 70%;*/
      }

      &::before {
        /*background: rgba(0,0,0, .2);*/
      }
    }

    .paging-wrap {
      text-align: center;
    }
  }



</style>